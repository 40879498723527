@tailwind base;
@tailwind components;
@tailwind utilities;
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  /* background-color: #faf8fb; */
}
:root {
  --text-white: #ffffff;
  --cheeryPink-default: #eb2283;
  --cheeryPink-opacity: #eb228390;
  --cheeryRed-default: #f33956;
  --cheeryRed-opacity: #f3395690;
  --main-purple: #9c1ad4;
  --cherryPurple-opacity: #9c1ad450;
}

/* 폰트 적용 */

@font-face {
  font-family: "SUIT-Variable";
  src: url("fonts/SUIT-Variable.woff2") format("woff2");
  font-weight: regular semibold bold;
  font-style: normal;
}
.inputTextMinSize {
  @apply text-[16px];
}
body {
  font-family: "SUIT Variable", sans-serif;
}

/* 드롭다운 또는 스크롤이 있는 요소에 대한 스타일 지정 */
.scroll-dropdown-adjust {
  position: relative; /* 드롭다운 요소의 위치를 상대적으로 설정 */
  overflow-y: auto; /* 세로 스크롤이 필요할 때 스크롤을 허용 */
  /* padding-right: 15px; 오른쪽에 패딩을 추가하여 스크롤 바를 안쪽으로 밀어 넣음 */
  /* margin-right: -5px; 스크롤 바를 왼쪽으로 약간 이동 */
}

/* 스크롤 바 전체에 대한 스타일 지정  */
::-webkit-scrollbar {
  width: 15px; /* 스크롤 바의 너비 */
  height: 8px; /* 스크롤 바의 높이 */
  /* background-color: var(--text-white); 스크롤 바의 배경색 */
}

/* 스크롤 바의 색상 지정 */
::-webkit-scrollbar-thumb {
  background-color: var(--cheeryRed-default); /* 스크롤 바의 색상 */
  border-radius: 8px;
  border: 4px solid rgba(0, 0, 0, 0); /* 투명한 border-right로 여백 생성 */
  background-clip: padding-box; /* 배경이 padding 영역 내에만 그려지도록 함 */
}

/* 스크롤 바 호버 시 색상 지정 */
::-webkit-scrollbar-thumb:hover {
  background-color: var(--cheeryRed-default); /* 스크롤 바의 색상 */
}
.tooltip {
  @apply absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 bg-black text-white text-xs rounded py-1 px-2 opacity-0 transition-opacity duration-300 whitespace-nowrap;
}
.tooltip::after {
  content: "";
  @apply absolute top-full left-1/2 transform -translate-x-1/2 border-4 border-transparent border-t-black;
}
/* wrapper custom style */
.all-bg-color {
  @apply bg-back-lGray;
}

.wrapper {
  @apply w-full min-h-screen bg-back-lGray;
}

.cherry-wrapper {
  @apply pt-[5rem] max-w-[1144px] z-10 ml-52 navCollapes:m-auto;
}

.cherry-header-wrapper {
  @apply z-20  max-w-[1144px] ml-52 navCollapes:m-auto pr-4;
}

.cherry-landing-wrapper {
  @apply max-w-[1144px] m-auto mt-20 mb-8;
}

/* editor custom style */
@layer utilities {
  .neon-border-top {
    @apply relative;
  }
  .neon-border-top::before {
    content: "";
    position: absolute;
    top: -5px;
    left: 10px;
    width: 100%;
    height: 2px;
    background: transparent;
    box-shadow: 0 0 10px #84c3ff, 0 0 10px #84c3ff, 0 0 30px #84c3ff;
    pointer-events: none;
  }
  .neon-border-bottom {
    @apply relative;
  }
  .neon-border-bottom::after {
    content: "";
    position: absolute;
    bottom: -5px;
    left: 10px;
    width: 100%;
    height: 2px;
    background: transparent;
    box-shadow: 0 0 10px #84c3ff, 0 0 10px #84c3ff, 0 0 30px #84c3ff;
    pointer-events: none;
  }
}

/* animation slide */
@keyframes slide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.slide-container {
  overflow: hidden;
  width: 100%;
}
.slide-track {
  display: flex;
  width: 100%;
  animation: slide 40s linear infinite;
}
.slide-item {
  flex: 0 0 16.666%;
  box-sizing: border-box;
}

@keyframes slideIn {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideOut {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-65%);
  }
}

.slidein {
  animation: slideIn 0.5s forwards;
}

.slideout {
  animation: slideOut 0.5s forwards;
}

.carousel-container {
  overflow: hidden;
  width: 100%;
}

.carousel-wrapper {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.bg-stripes-pink {
  background-image: linear-gradient(
    45deg,
    #f77488 25%,
    transparent 25%,
    transparent 50%,
    #f77488 50%,
    #f77488 75%,
    transparent 75%,
    transparent
  );
  background-size: 1rem 1rem;
}

.bg-gradient-modal {
  background: conic-gradient(
    white 0deg,
    #f2eeff 15deg,
    white 100deg,
    #ffebf5 260deg,
    white 350deg
  );
}

/* 토스트 메세지 */
body .Toastify__toast--success {
  background-color: #eefff1;
  border: 1px solid #51cf66;
}

body .Toastify__toast--warning {
  background-color: #fff5e0;
  border: 1px solid #fab007;
}

body .Toastify__toast--error {
  background-color: #ffe9e9;
  border: 1px solid #f03f3f;
}

body .Toastify__toast--info {
  background-color: #daeeff;
  border: 1px solid #238be6;
}

body .Toastify__toast {
  border-radius: 20px;
  padding: 15px;
  font-size: 14px;
  line-height: 14px;
  color: #000;
}

/* conponents */
.defaultInputStyle {
  @apply px-[12px] w-full py-[10px] rounded-[10px] text-[#858e96] text-regular16 border border-[#acb5bd];
}
